import React from 'react';
import linkedInIcon from '../images/linkedin.png'

const PersonBlock = ({person}) => {

    const profileImage = person.image == null ? 'no-profile.jpg' : person.image;

    return (
		<article className='PersonBlock'>
            <img className='PersonBlock-image' src={`/profile-images/${profileImage}`}/>
            <h2 className='PersonBlock-name'>{person.name}</h2>
            <h3 className='PersonBlock-title'>{person.title}</h3>
            <section className='PersonBlock-numbers'>
                {person.office && <p>Office: {person.office}</p>}
                {person.direct && <p>Direct: {person.direct}</p>}
                {person.mobile && <p>Mobile: {person.mobile}</p>}
            </section>
            <p className='PersonBlock-email'>Email:<a href={`mailto:${person.email}`}>{person.email}</a></p>
            {person.linkedIn && <p>LinkedIn: 
                <a href={`https://www.linkedin.com/in/${person.linkedIn}`} target="_blank">
                    <img className={'PersonBlock-icon'} src={linkedInIcon}/>
                </a>
            </p>}

        </article>
	)
}

export default PersonBlock;