import React, { useContext, useEffect, useState, useRef } from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Button from '../components/Button'
import SplitText from '../components/SplitText'
import ParallaxImage from '../components/ParallaxImage'
import { ContextLoader } from '../context';
import { detectElementsOnScreen, killObservers } from '../helpers/detectElementsOnScreen'
import getBase64 from '../helpers/getBase64'
import { RichText } from 'prismic-reactjs'
import emailjs from 'emailjs-com'
import PersonBlock from '../components/PersonBlock'

const FORM_TEMPLATE_ID = 'contact_form'
const CONTACT_SERVICE_ID = process.env.GATSBY_EMAILJS_SERVICE_ID
const CONTACT_USER_ID = process.env.GATSBY_EMAILJS_USER_ID

const GetInTouch = ({ data }) => {

	const [isLoading] = useContext(ContextLoader);
	useEffect(() => {
		if (isLoading === true) return
		const [observers, elements] = detectElementsOnScreen()
		return () => {
			killObservers(observers, elements)
		}
	}, [isLoading])

	const prismicNavigation = data.prismicNavigation
	const prismicFooter = data.prismicFooter
	const document = data.prismicGetInTouch.data

	const banner = {
		title: document.banner_title,
		description: document.description
	}

	const contact = {
		title: document.section_contact_title,
		list: document.section_contact_list,
		image: document.section_contact_image
	}

	const offices = {
		title: document.section_offices_title,
		list: document.section_offices_list,
		image: document.section_offices_image
	}

	const careers = {
		title: document.section_careers_title,
		description: document.section_careers_description,
		buttonText: document.section_careers_button_text,
		messages: {
			loading: document.section_careers_message_loading,
			success: document.section_careers_message_success,
			error: document.section_careers_message_error
		},
		imageLeft: document.section_careers_image_left,
		imageRight: document.section_careers_image_right
	}

	const userInfosDefault = {
		firstname: '',
		lastname: '',
		email: '',
		phone: '',
		street_address: '',
		city: '',
		state_province: '',
		country: '',
		street_address_2: '',
		preferred_way_of_contact: '',
		// resume: '',
		comments: ''
	}
	const [userInfos, setUserInfos] = useState(userInfosDefault)
	const [showSuccessMessage, setShowSuccessMessage] = useState(null)
	const [showErrorMessage, setShowErrorMessage] = useState(null)
	const [isSubmissionLoading, setIsSubmissionLoading] = useState(false)
	const formEl = useRef(null)
	const inputUploadEl = useRef(null)

	const handleInputChange = (property, value) => {
		const userInfosClone = {...userInfos}
		userInfosClone[property] = value
		setUserInfos(userInfosClone)
	}

	const handleInputUploadChange = () => {
		const userInfosClone = {...userInfos}
		const inputUploadFile = inputUploadEl.current.files[0];
		if (inputUploadFile === undefined) return;
		getBase64(inputUploadFile).then(data => {
			userInfosClone.resume = data
			setUserInfos(userInfosClone)
		})
	}

	const onButtonClick = e => {
		const isFormValid = formEl.current.reportValidity()

		if (!isFormValid) return

		setShowSuccessMessage(false)
		setShowErrorMessage(false)
		setIsSubmissionLoading(true)

		emailjs.send(CONTACT_SERVICE_ID, FORM_TEMPLATE_ID, userInfos, CONTACT_USER_ID)
			.then(
				res => {
					setUserInfos(userInfosDefault)
					setIsSubmissionLoading(false)
					setShowSuccessMessage(true)
				},
				err => {
					setIsSubmissionLoading(false)
					setShowErrorMessage(true)
				}
			)
	}

	const pageTitle = "Architectural Terracotta - Cladding | Shildan Group";
	const metaDescription = "Want to learn how to turn your project from a building into a statement piece? Schedule a consultation to learn about architectural terracotta cladding.";
	const metaKeywords = "Architectural terracotta, cladding";

  return (
    <div>
      <SEO title={pageTitle} description={metaDescription} keywords={metaKeywords} />
			<Header currentPage="get_in_touch" navigation={prismicNavigation} footer={prismicFooter} />
			<section className="GetInTouch">
				<h1
					className="GetInTouch__Title"
					data-is-on-screen
				>
					<SplitText splitBy="lines" hasMask>
						{banner.title.text}
					</SplitText>
				</h1>
				<h3
					className="GetInTouch__Description"
					data-is-on-screen
				>
					<SplitText splitBy="words">
						<RichText render={banner.description.raw} />
					</SplitText>
				</h3>

				<section className="GetInTouch__Contact">
					<div className="GetInTouch__ContactLeft">
						<h2
							className="GetInTouch__ContactTitle"
							data-is-on-screen
						>
							<SplitText splitBy="lines" hasMask>
								{contact.title.text}
							</SplitText>
						</h2>
						{/* <ul className="GetInTouch__ContactList">
							{contact.list.map((item, index) => (
								<li
									className="GetInTouch__ContactListItem"
									key={`contact-list-item-${index}`}
								>
									<span className="GetInTouch__ContactListLabel">
										{item.label.text}
									</span>
									<a
										className="GetInTouch__ContactListLink"
										href={item.link.url}
										target="_blank"
										rel="noreferrer"
									>
										{item.icon.fluid !== null &&
											<img
												className="GetInTouch__ContactListIcon"
												src={item.icon.fluid.src}
												srcSet={item.icon.fluid.srcSet}
												alt={item.icon.alt}
											/>
										}
										{item.icon.fluid === null &&
											<p> {item.link_label.text} </p>
										}
									</a>
								</li>
							))}
						</ul> */}
							<ul className="GetInTouch__OfficesListContact">
							{offices.list.slice(0,2).map((item, index) => (
								<li
									className="GetInTouch__OfficesListItemInContact"
									key={`offices-list-item-${index}`}
								>
									<h6 className="GetInTouch__OfficesListItemTitle">
										{item.title.text}
									</h6>
									<div className="GetInTouch__OfficesListItemContent keep-linebreaks-on-mobile">
										<RichText render={item.content.raw} />
									</div>
								</li>
							))}
						</ul>
						<ul class="horizontal-list">
							{contact.list.slice(-3).map((item, index) => (
									<li
										className="GetInTouch__ContactListItemSpacing"
										key={`contact-list-item-${index}`}
									>
										<a
											className="GetInTouch__ContactListLink"
											href={item.link.url}
											target="_blank"
											rel="noreferrer"
										>
											{item.icon.fluid !== null &&
												<img
													className="GetInTouch__ContactListIcon"
													src={item.icon.fluid.src}
													srcSet={item.icon.fluid.srcSet}
													alt={item.icon.alt}
												/>
											}
											{item.icon.fluid === null &&
												<p> {item.link_label.text} </p>
											}
										</a>
									</li>
								))}
						</ul>
					</div>
					<div className="GetInTouch__ContactImageContainer">
						<ParallaxImage
							src={contact.image.fluid.src}
							srcSet={contact.image.fluid.srcSet}
							alt={contact.image.alt}
							sizes="(max-width: 414px) and (min-height: 500px) 100vw, 69.44vw"
							className="GetInTouch__ContactImage"
						/>
					</div>
				</section>


				<section className="GetInTouch__Offices">
					<h3
						className="GetInTouch__OfficesTitle"
						data-is-on-screen
					>
						<SplitText splitBy="lines" hasMask>
							People
						</SplitText>
					</h3>
					<ul className="GetInTouch__OfficesList PeopleList">
						<li>Our people are at the core of everything we do. With dedication, passion, and responsibility, our team provides state of the art products, systems, and services, while overcoming challenges and building relationships. Our legacy projects around the world are shining examples of our team’s commitment and sense of ownership.</li>
					</ul>

						<section className='people-row'>
							<PersonBlock person={{
								image: 'Moshe Steinmetz, Shildan Group, Terracotta Rainscreen.jpg',
								name: 'Moshe Steinmetz', 
								title: 'President', 
								office: '215.525.4510',
								direct: '215.525.4515',
								mobile: '516.639.8855',
								email: ' msteinmetz@shildan.com',
								linkedIn: 'moshe-steinmetz-7353b321'
							}}/>

							<PersonBlock person={{
								image: 'Jen Marchesani 3, Shildan Group, Terracotta Rainscreen.jpeg',
								name: 'Jen Marchesani', 
								title: 'Vice President, Sales & Marketing', 
								office: '215.525.4510',
								direct: '215.525.4512',
								mobile: '856.466.1960',
								email: ' jen@shildan.com',
								linkedIn: 'jen-marchesani-08b74a26'
							}}/>

							<PersonBlock person={{
								image: 'Todd Petrillo, Shildan Group, Terracotta Rainscreen.jpg',
								name: 'Todd Petrillo', 
								title: 'Vice President, Operations', 
								office: '215.525.4510',
								direct: '215.525.4517',
								mobile: '267.414.3976',
								email: ' tpetrillo@shildan.com',
								linkedIn: 'todd-t-j-petrillo-48031b10'
							}}/>

							<PersonBlock person={{
								image: 'Jeremy Jester, Shildan Group, Terracotta Rainscreen.jpg',
								name: 'Jeremy Jester', 
								title: 'Vice President, Preconstruction', 
								office: '215.525.4510',
								direct: '215.525.4514',
								mobile: '352.328.1348',
								email: ' jjester@shildan.com',
								linkedIn: 'jeremy-jester-14165b24'
							}}/>
					</section>

					<section className='people-row'>

							<PersonBlock person={{
								image: 'Lee Hendershot, Shildan Group, Terracotta Rainscreen.jpg',
								name: 'Lee Hendershot, BSArchE', 
								title: 'Vice President, Project Management', 
								office: '215.525.4510',
								direct: '215.525.4516',
								mobile: '267.934.3971',
								email: ' lhendershot@shildan.com',
								linkedIn: 'lee-hendershot-20b18037'
							}}/>

							<PersonBlock person={{
								image: 'Dedy Blaustein, Shildan Group, Terracotta Rainscreen.jpg',
								name: 'Dedy Blaustein, B.Arch', 
								title: 'Head Of Design / Director of Australian Market', 
								office: '61 2 9371 8386',
								mobile: '61 434 610 044',
								email: ' dblaustein@shildan.com',
								linkedIn: 'dedy-blaustein-0a202923'
							}}/>

							<PersonBlock person={{
								image: 'Matt Taberski, Shildan Group, Terracotta Rainscreen.jpg',
								name: 'Matt Taberski', 
								title: 'New York Regional Sales Manager', 
								office: '215.525.4510',
								direct: '212.805.8118',
								mobile: '646.988.6270',
								email: ' mtaberski@shildan.com',
								linkedIn: 'matt-taberski-932a003a'
							}}/>

							<PersonBlock person={{
								image: 'Barak Guttmann, Shildan Group, Terracotta Rainscreen.jpg',
								name: 'Barak Guttmann', 
								title: 'Estimator', 
								office: '215.525.4510',
								email: ' bguttmann@shildan.com'
							}}/>

					</section>

					<section className='people-row'>

							<PersonBlock person={{
								image: 'Steve Szymanski, Shildan Group, Terracotta Rainscreen.jpg',
								name: 'Stephen Szymanski', 
								title: 'Senior Project Manager', 
								office: '215.525.4510',
								direct: '215.253.7553',
								mobile: '856.638.8612',
								email: ' sszymanski@shildan.com'
							}}/>


							<PersonBlock person={{
								image: 'Bob Andrews, Shildan Group, Terracotta Rainscreen.jpg',
								name: 'Bob Andrews', 
								title: 'Project Manager', 
								office: '215.525.4510',
								direct: '856.261.8874',
								mobile: '215.253.7555',
								email: ' bandrews@shildan.com',
								linkedIn: 'robert-andrews-44766469'
							}}/>

							<PersonBlock person={{
								image: 'Alex Katz, Shildan Group, Terracotta Rainscreen.jpg',
								name: 'Alex Katz', 
								title: 'Project Manager', 
								office: '215.525.4510',
								mobile: '267.443.3448',
								email: ' akatz@shildan.com',
								linkedIn: 'alex-katz-a9517778'
							}}/>

							<PersonBlock person={{
								image: 'Molly Holmes, Shildan Group, Terracotta Rainscreen.jpg',
								name: 'Molly Holmes', 
								title: 'Office Manager', 
								office: '215.525.4510',
								direct: '215.525.4523',
								email: ' mholmes@shildan.com',
								linkedIn: 'molly-holmes'
							}}/>


					</section>

					<section className='people-row'>


							<PersonBlock person={{
								image: 'Ben Loshinsky, Shildan Group, Terracotta Rainscreen.jpg',
								name: 'Ben Loshinsky', 
								title: 'Implementation Specialist', 
								office: '215.525.4510',
								email: ' bloshinsky@shildan.com'
							}}/>

							<PersonBlock person={{
								image: 'Angela Eisele, Shildan Group, Terracotta Rainscreen.jpg',
								name: 'Angela Eisele', 
								title: 'Bookkeeper', 
								office: '215.525.4510',
								mobile: '215.525.4518',
								email: ' angela@shildan.com',
								linkedIn: 'angela-eisele-726a0588'
							}}/>

							<PersonBlock person={{
								name: 'General Sales', 
								title: 'Northeast, West Coast, Midwest, Florida, Texas & all other inquiries',
								office: '215.525.4510',
								email: ' jen@shildan.com'
							}}/>

					</section>			
					
				</section>

				<section className="GetInTouch__Offices">
					<h3
						className="GetInTouch__OfficesTitle"
						data-is-on-screen
					>
						<SplitText splitBy="lines" hasMask>
							{offices.title.text}
						</SplitText>
					</h3>
				
				</section>

				<ul className="GetInTouch__OfficesList">
						{offices.list.map((item, index) => (
							<li
								className="GetInTouch__OfficesListItem"
								key={`offices-list-item-${index}`}
							>
								<h6 className="GetInTouch__OfficesListItemTitle">
									{item.title.text}
								</h6>
								<div className="GetInTouch__OfficesListItemContent keep-linebreaks-on-mobile">
									<RichText render={item.content.raw} />
								</div>
							</li>
						))}
					</ul>

				<section className="GetInTouch__Careers">
					<h3
						className="GetInTouch__CareersTitle"
						data-is-on-screen
					>
						<SplitText splitBy="lines" hasMask>
							{careers.title.text}
						</SplitText>
					</h3>
					<div
						className="GetInTouch__CareersDescription"
						data-is-on-screen
					>
						<SplitText splitBy="words">
							<RichText render={careers.description.raw} />
						</SplitText>
					</div>
					<form
						className="GetInTouch__CareersForm"
						ref={formEl}
					>
						<div className="GetInTouch__CareersFormContainer">
							<div className="GetInTouch__CareersInputContainer">
								<input
									className="GetInTouch__CareersInput"
									placeholder="First Name*"
									type="text"
									required
									value={userInfos.firstname}
									onChange={e => handleInputChange('firstname', e.target.value)}
								/>
							</div>
							<div className="GetInTouch__CareersInputContainer">
								<input
									className="GetInTouch__CareersInput"
									placeholder="Last Name*"
									type="text"
									required
									value={userInfos.lastname}
									onChange={e => handleInputChange('lastname', e.target.value)}
								/>
							</div>
							<div className="GetInTouch__CareersInputContainer">
								<input
									className="GetInTouch__CareersInput"
									placeholder="Email Address*"
									type="email"
									required
									value={userInfos.email}
									onChange={e => handleInputChange('email', e.target.value)}
								/>
							</div>
							<div className="GetInTouch__CareersInputContainer">
								<input
									className="GetInTouch__CareersInput"
									placeholder="Phone Number*"
									type="tel"
									required
									value={userInfos.phone}
									onChange={e => handleInputChange('phone', e.target.value)}
								/>
							</div>
							<div className="GetInTouch__CareersInputContainer">
								<input
									className="GetInTouch__CareersInput"
									placeholder="Street Address*"
									type="text"
									required
									value={userInfos.street_address}
									onChange={e => handleInputChange('street_address', e.target.value)}
								/>
							</div>
							<div className="GetInTouch__CareersInputContainer">
								<input
									className="GetInTouch__CareersInput"
									placeholder="City*"
									type="text"
									required
									value={userInfos.city}
									onChange={e => handleInputChange('city', e.target.value)}
								/>
							</div>
							<div className="GetInTouch__CareersInputContainer">
								<input
									className="GetInTouch__CareersInput"
									placeholder="State / Province*"
									type="text"
									required
									value={userInfos.state_province}
									onChange={e => handleInputChange('state_province', e.target.value)}
								/>
							</div>
							<div className="GetInTouch__CareersInputContainer">
								<input
									className="GetInTouch__CareersInput"
									placeholder="Country*"
									type="text"
									required
									value={userInfos.country}
									onChange={e => handleInputChange('country', e.target.value)}
								/>
							</div>
							<div className="GetInTouch__CareersInputContainer">
								<input
									className="GetInTouch__CareersInput"
									placeholder="Street Address 2"
									type="text"
									value={userInfos.street_address_2}
									onChange={e => handleInputChange('street_address_2', e.target.value)}
								/>
							</div>
							<div className="GetInTouch__CareersInputContainer">
								<input
									className="GetInTouch__CareersInput"
									placeholder="Where should we contact you?"
									type="text"
									value={userInfos.preferred_way_of_contact}
									onChange={e => handleInputChange('preferred_way_of_contact', e.target.value)}
								/>
							</div>
						</div>
						<div className="GetInTouch__CareersInputTextareaContainer">
							<textarea
								className="GetInTouch__CareersInputTextarea"
								placeholder="Additional Comments"
								rows="6"
								value={userInfos.comments}
								onChange={e => handleInputChange('comments', e.target.value)}
							/>
						</div>
						<Button
							className="GetInTouch__CareersButton"
							modifier="big"
							onClick={onButtonClick}
						>
							{careers.buttonText.text}
						</Button>
						<div className="GetInTouch__SubmitMessages">
							{isSubmissionLoading &&
								<div className="GetInTouch__SubmitMessage GetInTouch__SubmitMessage--Loader">
									{careers.messages.loading.text}
								</div>
							}
							{showSuccessMessage &&
								<div className="GetInTouch__SubmitMessage GetInTouch__SubmitMessage--Success">
									{careers.messages.success.text}
								</div>
							}
							{showErrorMessage &&
								<div className="GetInTouch__SubmitMessage GetInTouch__SubmitMessage--Error">
									{careers.messages.error.text}
								</div>
							}
						</div>
					</form>
					<div className="GetInTouch__CareersImages">
						<ParallaxImage
							src={careers.imageLeft.fluid.src}
							srcSet={careers.imageLeft.fluid.srcSet}
							alt={careers.imageLeft.alt}
							sizes="(max-width: 414px) and (min-height: 500px) 100vw, 25.13vw"
							className="GetInTouch__CareersImageLeft"
						/>
						<ParallaxImage
							src={careers.imageRight.fluid.src}
							srcSet={careers.imageRight.fluid.srcSet}
							alt={careers.imageRight.alt}
							sizes="(max-width: 414px) and (min-height: 500px) 100vw, 44.3vw"
							className="GetInTouch__CareersImageRight"
						/>
					</div>
				</section>

			</section>

			<Footer footer={prismicFooter.data} />
    </div>
  )
}

export const query = graphql`
	query GetInTouch {
		prismicGetInTouch {
			data {
				banner_title {
					text
				}
				description {
					raw
				}
				section_contact_title {
					text
				}
				section_contact_list {
					label {
						text
					}
					link {
						url
					}
					link_label {
						text
					}
					icon {
						alt
						fluid {
							src
							srcSet
						}
					}
				}
				section_contact_image {
					alt
					fluid {
						src
						srcSet
					}
				}
				section_offices_title {
					text
				}
				section_offices_list {
					title {
						text
					}
					content {
						raw
					}
				}
				section_offices_image {
					alt
					fluid {
						src
						srcSet
					}
				}
				section_careers_title {
					text
				}
				section_careers_description {
					raw
				}
				section_careers_button_text {
					text
				}
				section_careers_message_loading {
					text
				}
				section_careers_message_success {
					text
				}
				section_careers_message_error {
					text
				}
				section_careers_image_left {
					alt
					fluid {
						src
						srcSet
					}
				}
				section_careers_image_right {
					alt
					fluid {
						src
						srcSet
					}
				}
			}
		}
		prismicNavigation {
      ...HeaderQuery
    }
		prismicFooter {
			...FooterQuery
		}
	}
`

export default GetInTouch